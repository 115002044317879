<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row class="bradcrumb">
      <router-link :to="{ name: 'home' }" class="root"> Dashboard </router-link>
      /
      <router-link :to="{ name: 'add-periodicals' }" class="root">
        Add Periodicals
      </router-link>
      <span class="child">/ Periodicals Item </span>
    </v-row>
    <br />
    <v-card class="card">
      <v-card-title class="heading justify-center">Periodicals Item</v-card-title>
      <v-card-text>
        <div class="bookdata">
          <div class="bookInfo">
            <!-- <v-row> -->
            <v-col sm="6" class="value" lg="1">CI NO</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.isbn }}</v-col>
            <v-col sm="6" class="value" lg="1">Title</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.title }}</v-col>
            <v-col sm="6" class="value" lg="1">Total Copies</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.copies }}</v-col>
          </div>
          <!-- </v-row> -->
          <div class="d-flex">
            <v-col sm="6" class="value" lg="1">sub From</v-col>
            <v-col sm="6" lg="3" class="data">:{{ bookData.subscription_from }}</v-col>
            <v-col sm="6" class="value" lg="1">sub To</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.subscription_to }}</v-col>
            <v-col sm="6" class="value" lg="1">Issue</v-col>
            <v-col sm="6" lg="3" class="data"
              >: {{ bookData.issue_from }} TO {{bookData.issue_to}}</v-col
            >
          </div>
          <div class="d-flex">
         
            <v-col sm="6" class="value" lg="1">Publisher</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.publisher }}</v-col>
            <v-col sm="6" class="value" lg="1">Vol From</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.volume_from }}</v-col>
            <v-col sm="6" class="value" lg="1">Vol To</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.volume_to }}</v-col>
          </div>
          <div class="d-flex">
            <v-col sm="6" class="value" lg="1">Author(s)</v-col>
            <v-col sm="6" lg="11" class="data"
              >:
              <span
                class="pr-3"
                v-for="(auth, index) in bookData.authors"
                :key="index"
                >{{ bookData.authors[index] }}</span
              ></v-col
            >
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="bookItemlist"
          class="elevation-1 nowrap"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                single-line
                hide-details
              >
              </v-text-field>
              <span style="width: 20px"></span>
              <!-- <v-btn class="mb-2 button" >Export</v-btn> -->
              <download-excel
                class="mar"
                :data="bookItemlist"
                :fields="export_table_headers"
                worksheet="bookItemlist"
                name="BookItem.xls"
              >
                <v-btn color="success" dark class="mb-2">
                  Export<v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-form v-model="valid" ref="form">
                    <v-card-title class="heading">
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" class="fields">
                            <label>Accession Number</label>
                            <v-text-field
                              dense
                              outlined
                              v-model="editedItem.access_no"
                              persistent-hint
                              :disabled="!inSave"
                              :error="errorMsg.access_no ? true : false"
                              :error-messages="
                                errorMsg.access_no ? 'Required' : ''
                              "
                              :rules="[(v) => !!v || 'required']"
                            ></v-text-field>
                          </v-col>
                          <!--<v-col cols="12" class="fields">
                                                        <label>Barcode</label>
                                                      <v-text-field
                                                            dense
                                                            outlined
                                                            v-model="editedItem.title"
                                                            item-text="roleLink_list_id"
                                                            item-value="id"
                                                            persistent-hint
                                                            :disabled="!inSave"
                                                            :error="errorMsg.title?true:false"
                                                            :error-messages="errorMsg.title?'Required':''"
                                                            :rules="[v => !!v || 'required']"
                                                        ></v-text-field> 
                                                    </v-col>-->
                          <!--   <v-col cols="12" class="fields">
                                                        <label>BookStatus</label>
                                                        <v-autocomplete dense outlined :items="bookStatus" item-value="book_statusId" item-text="displayname" v-model="editedItem.book_statusId" persistent-hint></v-autocomplete>
                                                    </v-col>-->
                          <!-- <v-col cols="12" class="fields">
                                                        <label>Member</label>
                                                        <v-autocomplete dense outlined :items="memberList" item-value="memberId" item-text="member" v-model="editedItem.memberId" persistent-hint></v-autocomplete>
                                                    </v-col> -->

                          <!--
                                                    <v-col cols="12" sm="12" md="8" lg="8" class="fields">
                                                        <label>Borrowed Date</label>
                                                        <v-menu v-model="showPicker1" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                    v-model="editedItem.borrowed_date"
                                                                    placeholder="Select Date"
                                                                    :disabled="!inSave"
                                                                    persistent-hint
                                                                    dense
                                                                    readonly
                                                                    outlined
                                                                    v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="editedItem.borrowed_date" no-title @input="showPicker1 = false"></v-date-picker>
                                                        </v-menu>

                                                        <label>Due Date</label>
                                                        <v-menu v-model="showPicker" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field v-model="editedItem.due_date" placeholder="Select Date"  persistent-hint :disabled="!inSave" readonly dense outlined v-on="on"></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="editedItem.due_date" no-title @input="showPicker = false"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
-->
                       <!--   <v-row justify="center" class="ma-5">
                            <router-link
                              :to="{
                                name: 'issue-book',
                                params: { assno: editedItem.access_no,id:editedItem.id },
                              }"
                              class="root"
                            >
                              <v-btn dark class="primary"> ISSUE BOOK</v-btn>
                            </router-link>

                            <router-link
                              :to="{ name: 'reserve-book' , params: { assno: bookData.isbn,id:editedItem.id }}"
                              class="root"
                              
                            >
                              <v-btn dark class="primary" > RESERVE BOOK</v-btn>
                            </router-link>

                          
                               <v-btn dark class="primary" @click="pushSingleBookToReserve(editedItem)"> RESERVE BOOK</v-btn> 
                           
                          </v-row>
                          <v-col cols="12" class="fields">
                            <label>Rack No</label>
                            <v-autocomplete
                              dense
                              outlined
                              @change="getRackBlockNo()"
                              :items="racklist"
                              item-value="rackId"
                              item-text="rackNo"
                              v-model="editedItem.rackId"
                              persistent-hint
                            ></v-autocomplete>
                          </v-col>
                          <p style="color: red" v-if="!isRackAvailable">
                            Selected Rack and Block not available!!!
                          </p>
                          <v-col cols="12" class="fields">
                            <label>Block No</label>
                            <v-autocomplete
                              dense
                              outlined
                              :items="blockList"
                              @change="checkRackAvailability"
                              item-value="block_no"
                              item-text="block_no"
                              v-model="editedItem.block_no"
                              persistent-hint
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12" class="fields">
                            <label>Withdrawal No</label>
                            <v-text-field
                              dense
                              outlined
                              v-model="editedItem.w_no"
                              item-text="roleLink_list_id"
                              item-value="id"
                              persistent-hint
                            ></v-text-field>
                          </v-col>-->

                          <v-col cols="12" class="fields">
                            <label>Date Of Periodic Entry</label>
                            <v-menu
                              v-model="showPicker5"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              full-width
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="editedItem.periodic_entry_date"
                                  placeholder="Select Date"
                                  hint="YYYY/MM/DD"
                                  persistent-hint
                                  dense
                                  readonly
                                  outlined
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.periodic_entry_date"
                                no-title
                                @input="showPicker1 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                        <v-col cols="12" class="fields">
                                                        <label>BookStatus</label>
                                                        <v-autocomplete dense outlined :items="bookStatus" item-value="book_statusId" item-text="displayname" v-model="editedItem.book_statusId" persistent-hint></v-autocomplete>
                                        </v-col>

                        <!--  <v-col cols="12" class="fields">
                            <label>Publication Place</label>
                            <v-text-field
                              dense
                              outlined
                              v-model="editedItem.p_place"
                              item-text="roleLink_list_id"
                              item-value="id"
                              persistent-hint
                            ></v-text-field>
                          </v-col>-->
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="button-cancel" text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn
                        class="button"
                        @click="save"
                        :disabled="!valid || !isRackAvailable"
                        >Save</v-btn
                      >
                    </v-card-actions>
                    <!-- :disabled="!valid" -->
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon>
            </div>
          </template>
          <template v-slot:item.bookItem="{ item }">
            <router-link
              :to="{ name: 'book-item', params: { bookId: item.id } }"
              style="text-decoration: none"
            >
              <v-btn small color="info">Book Item</v-btn>
            </router-link>
          </template>
          <template v-slot:item.borrowed_date="{ item }">
            <p class="m-0" v-if="item.book_status !== 'Issued'">-</p>
            <p class="m-0" v-else>{{ item.borrowed_date }}</p>
          </template>
          <template v-slot:item.due_date="{ item }">
            <p class="m-0" v-if="item.book_status !== 'Issued'">-</p>
            <p class="m-0" v-else>{{ item.due_date }}</p>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    export_table_headers: {
      "Sr.no.": "srno",
      "Accession No": "access_no",
      "Book Status": "book_status",
      "Rack No": "rackNo",
      "Block No": "block_no",
    },
    showPicker5: false,
    bookData: null,
    bookItemlist: [],
    racklist: [],
    bookStatus: [],
    valid: false,
    overlay: false,
    errorMsg: {
      roleLinkId: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    showPicker: false,
    showPicker1: false,
    selectedDate: null,
    menu1: false,
    menu2: false,
    org: "",
    inSave: true,
    isRackAvailable: true,
    dialog: false,
    headers: [
      { text: "Actions", value: "action", sortable: false },
      {
        text: "Issue No",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Accession Number",
        align: "left",
        sortable: true,
        value: "access_no",
      },
      {
        text: "Periodic Entry Date",
        align: "left",
        sortable: true,
        value: "periodic_entry_date",
      },
      {
        text: "Book Status",
        align: "left",
        sortable: true,
        value: "book_status",
      },

    
      //  { text: "Book Item(s)", value: "bookItem", sortable: false },
    ],
    bookCategoryList: [],
    roleLink_list: [],
    blockList: [],
    role_list: [],
    editedIndex: -1,
    editedItem: {
      periodic_entry_date:"",
      w_date: "",
      w_no: "",
      p_place: "",
      isactive: false,
      id: "",
      access_no: null,
      barcode: null,
      book_status: null,
      borrowed_date: null,
      due_date: null,
      rackNo: null,
      block_no: null,
      book_statusId: null,
      bookStatus: null,
      member: null,
      memnerId: null,
      rackId: null,
      
    },
    defaultItem: {
      isactive: false,
      id: "",
      access_no: null,
      barcode: null,
      book_status: null,
      borrowed_date: null,
      due_date: null,
      rackNo: null,
      block_no: null,
      book_statusId: null,
      bookStatus: null,
      member: null,
      memnerId: null,
      rackId: null,
      periodic_entry_date:null
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Book Item" : "Edit Book Item";
    },
  },

  // watch: {
  //     dialog(val) {
  //         val || this.close();
  //     },
  //     overlay(val) {
  //         val = () => {
  //             this.overlay = false;
  //         };
  //     },
  // },
  mounted() {
    this.onLoad();
  },
  methods: {
    pushSingleBookToReserve(bookItem){      
      this.$router.push({path: '/reserve-book', params: { singleBook: bookItem.access_no }})   
    },
    editItem(item) {
      console.log("this.editedItem", this.editedItem);
      // item.borrowed_date = moment(item.borrowed_date).format('YYYY-MM-DD')
      this.blockList.push({ block_no: item.block_no });
      console.log("item---", item);

      this.inSave = false;
      this.editedIndex = this.bookItemlist.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      var name = item.role;
      for (let x in this.role_list) {
        // alert(this.role_list[x].name+" : "+name)
        if (this.role_list[x].name == name) {
          this.editedItem.role = this.role_list[x].name;
          this.editedItem.roleId = this.role_list[x].id;
          // this.item.role = this.role_list[x].name
          break;
        }
      }
    },
    onLoad() {     
      //console.log("params----", this.$route.params.bookId);
      let data = {
        bookId: this.$route.params.bookId,
      };
      this.overlay = true;
      axios
        .post("/Librarian/getBookItemData", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            // this.bookCategoryList = res.data.bookCategory_list
            // this.roleLink_list = res.data.rack_list;
            // this.role_list = res.data.role_list;
            // this.org = res.data.org;
            this.bookData = res.data.bookData;
            this.bookItemlist = res.data.bookItemlist;
            this.racklist = res.data.racklist;
            this.bookStatus = res.data.bookStatus;

            this.bookList = res.data.book_list;
            this.bookTypeList = res.data.bookTypeList;
            this.bookFormatList = res.data.bookFormatList;
            this.bookCatList = res.data.bookCatList;
            this.languageList = res.data.languageList;
            this.publisherList = res.data.publisherList;
            this.libraryList = res.data.lib_list;
            this.authorList = res.data.authorList;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // ==this.$refs.form.reset()
      // setTimeout(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem);
      //     this.editedIndex = -1;
      // }, 300);
    },

    save() {
   
      if (this.editedIndex > -1) {
        if (this.$refs.form.validate()) {
          axios
            .post("/Librarian/editperiodicalsItem", this.editedItem)
            .then((res) => {
              if (res.data.msg == "success") {
                //window.alert(res.data.msg)
                // Object.assign(this.roleLink_list[this.editedIndex], this.editedItem);
                this.showSnackbar("#4caf50", "periodicals Updated Successfully..."); // show snackbar on success
                this.onLoad();
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", "periodicals Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      } else {
        // axios
        console.log("in else");
        //     .post("/Librarian/saveBook", this.editedItem)
        //     .then((res) => {
        //         if (res.data.msg == "success") {
        //             this.showSnackbar("#4caf50", "Book Added Successfully!!!"); // show snackbar on success
        //             this.onLoad();
        //         } else if (res.data.status.code == "NA") {
        //             this.showSnackbar("#b71c1c", "Book Already Present!!!"); // show snackbar on error
        //         }
        //     })
        //     .catch((error) => {
        //         window.console.log(error);
        //     });
        // this.close();
      }
    }, //save()

    getRackBlockNo() {
      console.log(this.editedItem);
      const data = {
        rackId: this.editedItem.rackId,
        block_no: this.editedItem.block_no,
      };
      console.log("data------", data);
      axios
        .post("/Librarian/getRackBlockNo", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.blockList = res.data.blockList;
            this.isRackAvailable = res.data.isRackAvailable;
          } else if (res.data.status.code == "NA") {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    checkRackAvailability() {
      const data = {
        rackId: this.editedItem.rackId,
        block_no: this.editedItem.block_no,
      };
      console.log("data----", data);
      axios
        .post("/Librarian/checkRackAvailability", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.isRackAvailable = res.data.isRackAvailable;
          } else if (res.data.status.code == "NA") {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    activeOn(item) {
      axios
        .post("/admin/rackIsactive", item)
        .then(() => {
          // this.sec_mode_list = res.data.user_list;
          this.showSnackbar("#4CAF50", "Rack updated successfully...");
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },

    isValidated() {
      if (this.editedItem.roleLinkId) {
        return true;
      } else {
        if (!this.editedItem.roleLinkId) {
          this.errorMsg.roleLinkId = true;
        }

        return false;
      }
    }, // .....end of isValidated()
  },
};
</script>
<style scoped>
.data {
  padding-left: 20px;
}
.bookdata {
  border: 2px solid gray;
  background: #d3d3d330;
  margin: 1rem 0rem 1rem 0rem;
  border-radius: 5px;
}
.value {
  white-space: nowrap;
  font-weight: bold;
  color: black;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}
.bookInfo {
  display: flex;
}
@media only screen and (max-width: 600px) {
  .bookInfo {
    /* display: block; */
  }
}
</style>
